<template>
  <div>
    <b-card>
      <b-card-header header-class="pt-0">
        <!-- <pre>{{ lessonDetailsDto }}</pre> -->
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4>تفاصيل الدروس {{ lessonDetailsDto.name }}</h4>
          <div>
            <b-form-radio-group id="radio-group-2" v-model="lessonDetailsDto.isFree" name="radio-sub-component">
              <b-form-radio :value="true">مجاني</b-form-radio>
              <b-form-radio :value="false">مدفوع</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </b-card-header>

      <b-card-body>
        <ValidationObserver ref="form">
          <b-row>
            <b-col lg="4">
              <ek-input-text name="name" label=" اسم الدروس " placeholder="ادخل اسم الدروس"
                v-model="lessonDetailsDto.name">
              </ek-input-text>
              <b-row no-gutters>
                <b-col lg="6" md="12">
                  <ek-input-select :options="years" v-model="lessonDetailsDto.yearId" placeholder="السنة"
                    name=" اختر السنة" label="السنة" :rules="[
                      {
                        type: 'required',
                        message: 'السنة مطلوبة',
                      },
                    ]">
                  </ek-input-select>
                </b-col>
                <b-col lg="6" md="12" class="pl-2">
                  <ek-input-select :options="semesters" placeholder="اختر الفصل" name=" اختر الفصل" label="الفصل"
                    v-model="lessonDetailsDto.semesterId" :rules="[
                      {
                        type: 'required',
                        message: 'الفصل مطلوب',
                      },
                    ]">
                  </ek-input-select>
                </b-col>
              </b-row>
              <ek-input-text name="name" label=" ترتيب الدروس " v-model="lessonDetailsDto.order"
                placeholder="ترتيب الدروس">
              </ek-input-text>
            </b-col>
            <b-col lg="4">
              <!-- جامعة كلية فرع  -->
              <ek-input-select name="الفرع" disabled label=" تابع ل جامعة - كلية - فرع "
                placeholder="جامعة - كلية - فرع" :options="departmentsSubjectsOptions"
                v-model="lessonDetailsDto.departmentId" @change="resetCourse">
              </ek-input-select>

              <ek-input-select name="المادة" v-if="
                lessonDetailsDto.departmentId &&
                universityNameCourse.find(
                  (un) => un.id == lessonDetailsDto.departmentId
                )
              " v-model="lessonDetailsDto.courseId" label=" تابع ل سنة - فصل -كورس- مادة "
                placeholder="تابع ل سنة - فصل -كورس- مادة " disabled :options="
                  universityNameCourse.find(
                    (un) => un.id == lessonDetailsDto.departmentId
                  ).courses
                ">
              </ek-input-select>
              <ek-input-textarea name="name" label="شرح الدروس" placeholder="شرح الدروس"
                v-model="lessonDetailsDto.description">
              </ek-input-textarea>
            </b-col>
            <!-- {{ this.lessonDetailsDto.url.url }} -->
            <b-col lg="4">
              <div class="position-relative">
                <b-button v-if="!filesUploaded" variant="primary" @click="onUploadFile" class="position-absolute"
                  style="z-index: 999; bottom:-60px; right: 10px">رفع الملف</b-button>
                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret
                  class="position-absolute" style="z-index: 999">
                  <template #button-content>
                    <div class="rounded-circle">
                      <unicon name="ellipsis-v" fill="#6101DF"></unicon>
                    </div>
                  </template>
                  <b-dropdown-item href="#" ref="myUploader" @click="fileWindowApi(storeFileThumb)">
                    <unicon name="edit-alt" fill="#6101DF" style="width: 25px"></unicon>
                    <span>تعديل ملف</span>
                  </b-dropdown-item>
                </b-dropdown>
                <div class="w-100" style="height: 280px">
                  <img class="rounded w-100 h-100" style="object-fit: cover" :src="
                    $store.getters['app/domainHost'] +
                    '/' +
                    lessonDetailsDto.url.thumbUrl
                  " alt="" />
                </div>
              </div>
              <!-- {{ newFileUrl }} -->
              <div class="mt-1">
                <div class="w-100" style="height: 280px" v-if="lessonDetailsDto.lessonType === 'Video'">
                  <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret
                    class="position-absolute" style="z-index: 999">
                    <template #button-content>
                      <div class="rounded-circle">
                        <unicon name="ellipsis-v" fill="#6101DF"></unicon>
                      </div>
                    </template>
                    <b-dropdown-item href="#" ref="myUploader" @click="fileWindowApi(storeFile)">
                      <unicon name="edit-alt" fill="#6101DF" style="width: 25px"></unicon>
                      <span>تعديل ملف</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- add if newFileUrl to auto playe it because when enter to page details url is not here 
                                        and if isDestroyVideo to reset url because it مكيشش  -->
                  <video v-if="newFileUrl && isDestroyVideo" controls autoplay class="h-100 w-100 rounded"
                    style="object-fit: cover">
                    <source :src="newFileUrl" type="video/mp4" />
                  </video>
                </div>

                <div class="w-100 d-flex justify-content-center align-items-center">
                  <a :href="
                    $store.getters['app/domainHost'] + '/' + lessonDetailsDto.url.url
                  " target="_blank">
                    <img v-if="lessonDetailsDto.lessonType === 'Document'" class="rounded"
                      style="cursor: pointer; object-fit: cover; width: 250px" src="
                                https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png    
                                " alt="" />
                  </a>
                </div>
              </div>
              <template v-if="isLoading">
                <div class="p-2">
                  <span class="text-primary">جاري رفع الملف
                  </span>
                  <b-progress class="mt-1" :value="uploadProgress" :max="100" show-progress animated>
                  </b-progress>
                </div>
              </template>
              <p v-if="!filesUploaded" class="my-1 text-warning">
                يجب رفع الملف الجديد ليتم حفظه
              </p>
            </b-col>
          </b-row>
        </ValidationObserver>
      </b-card-body>
    </b-card>
    <div v-if="
      getUserRoles() == Admin ||
      getUserRoles() == SuperAdmin ||
      getUserRoles() == DataEntry
    " class="d-flex justify-content-end align-items-center w-100">
      <AddQuestion class="w-50"></AddQuestion>
    </div>
    <div v-if="
      getUserRoles() == Admin ||
      getUserRoles() == SuperAdmin ||
      getUserRoles() == DataEntry
    ">
      <span>
        <div class="d-flex">
          <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
          <h4>محتوى الدرس ({{ lessonDetailsDto.question.length }}) سؤال</h4>
        </div>
      </span>
      <ek-table class="mt-2" :columns="columnsLessons" :items="lessonDetailsDto.question" @details="detailsLesson"
        @delete-selected="deleteQuestion">
        <template slot="items.dateCreated" slot-scope="{ value }">
          {{ new Date(value).toLocaleDateString() }}
        </template>
      </ek-table>
    </div>

    <div v-if="getUserRoles() == Teacher">
      <ek-table class="mt-2" :columns="columnsLessons" :items="lessonDetailsDto.question" @details="detailsLesson">
        <template #table-header>
          <div class="d-flex pt-1 pb-1">
            <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
            <h4>محتوى الدرس ({{ lessonDetailsDto.question.length }}) سؤال</h4>
          </div>
        </template>
        <template slot="items.dateCreated" slot-scope="{ value }">
          {{ new Date(value).toLocaleDateString() }}
        </template>
      </ek-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
import AddQuestion from "@admin/questionBank/components/AddQuestion.vue";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry, Teacher } from "@/router";

export default {
  props: {
    id: String,
  },
  components: {
    AddQuestion,
  },
  data() {
    return {
      SuperAdmin,
      Admin,
      DataEntry,
      Teacher,
      filesUploaded: true,
      isLoading: false,
      isDestroyVideo: true,
      file: {
        file: "",
        thumbFile: "",

      },
      columnsLessons: [
        {
          label: "رقم السؤال",
          field: "order",
        },
        {
          label: "السؤال",
          field: "question",
        },

        {
          label: "تاريخ الاضافة",
          field: "dateCreated",
        },
        {
          label: "تفاصيل",
          field: "details",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      teachers: ({ settings }) => settings.settingDto.teachers,
      lessonDetailsDto: ({ lessons }) => lessons.lessonDetailsDto,
      newFileUrl: ({ lessons }) => lessons.newFileUrl,
      universityNames: (state) => state.university.universityName,
      years: (state) => state.settings.yearList,
      semesters: (state) => state.settings.semesterList,
      uploadProgress: ({ lessons }) => lessons.uploadProgress,
      departmentsSubjectsOptions: ({ courses }) => courses.departmentsSubjectsOptions,
      universityNameCourse: ({ university }) => university.universityNameCourse,
    }),
    ...mapGetters(["questions"]),
  },
  methods: {
    ...mapActions([
      "getSettingTeacher",
      "addFileCourse",
      "getLessonDetails",
      "getNamesUniversity",
      "getSettingYear",
      "getSettingSemester",
      "getDepartmentsWithSubjects",
      "getNamesCourses",
      "updateLessonFile",
      "getFileLink",
      "deleteQuestion",
    ]),
    getUserRoles,
    fileWindowApi,
    storeFile(e) {
      this.file.file = e.file;
      this.filesUploaded = false
    },
    storeFileThumb(e) {
      this.file.thumbFile = e.file;
      this.filesUploaded = false
    },
    detailsLesson({ row }) {
      this.$router.push({ path: `/admin/questionBank/${row.id}` });
    },
    resetCourse() {
      this.lessonDetailsDto.courseId = "";
    },
    onUploadFile() {
      this.isDestroyVideo = false;
      this.isLoading = true
      this.updateLessonFile({
        id: this.lessonDetailsDto.url.id,
        file: this.file.file,
        thumbFile: this.file.thumbFile,
        deleteThumbFile: this.file.thumbFile ? true : false,
      }).then((res) => {
        console.log(res);
        this.lessonDetailsDto.url = { ...res };
        this.getFileLink(this.lessonDetailsDto.url.url);
        this.isDestroyVideo = true;
        this.filesUploaded = true
        this.isLoading = false;

        // this.getLessonDetails(this.id);
      }).catch(() => {
        this.isDestroyVideo = true;
        this.filesUploaded = false
        this.isLoading = false;

      })
    },
  },

  created() {
    this.getDepartmentsWithSubjects();
    this.getLessonDetails(this.id).then(() => {
      this.$store.commit("Fill_Select_Question", this.lessonDetailsDto);
      this.$store.dispatch("getFileLink", this.lessonDetailsDto.url.url);
    });
    this.getSettingTeacher();
    this.getNamesUniversity();
    this.getSettingYear();
    this.getSettingSemester();
    this.getNamesCourses();
  },
  beforeDestroy() {
    this.$store.commit("Reset_LessonDetails");
    console.log("in destroy ");
  },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
  right: 0 !important;
}
</style>
